.authentication1 {
    height: 100vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    text-align: center
}

.authentication1 .card {
    max-width: 450px;
    margin-top: 240px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12)
}

.authentication1 .card .header {
    margin-top: -70px;
    color: #16191a;
    margin-bottom: 20px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)
}

.authentication1 .card .header h1 {
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 10px
}

.authentication1 .card .title {
    margin: 0
}

.authentication1 .card .msg {
    font-size: 14px;
    font-weight: 400;
    text-transform: none
}

.authentication1 .l-social {
    margin: 0
}

.authentication1 .l-social li {
    display: inline-block
}

.authentication1 .l-social li a {
    text-align: center;
    padding: 5px 10px;
    color: #666
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .authentication1 .card {
        margin-top: 100px
    }
}

@media only screen and (max-width: 767px) {
    .authentication1 .card {
        margin-top: 100px
    }
}